@import 'src/styles/theme.scss';

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  color: $dark-blue;
}

.search {
  position: relative;
  font-family: $regular-font;
}
