@import 'src/styles/theme.scss';
@import 'src/styles/mixins.scss';

.fallback {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}
