@import 'src/styles/theme.scss';
@import 'src/styles/mixins.scss';

.loader-item {
  background-color: #ffffff;
  padding: 15px 10px;
  border-radius: 10px;
}

.loader {
  position: relative;

  svg {
    display: block;
  }
}
