@import 'src/styles/theme.scss';
@import 'src/styles/mixins.scss';

button {
  padding: 0;
  background-color: transparent;
  cursor: pointer;
}

.gallery {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  background-color: rgba(2, 45, 114, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;

  @include screen('mobile') {
    align-items: start;
  }
  .content {
    width: 1042px;
    display: flex;
    flex-direction: column;

    @include screen('tablet') {
      width: 95%;
    }

    .header {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      position: relative;

      @include screen('mobile') {
        margin-top: 10px;
      }

      .image-amount {
        font-family: $medium-font;
        color: #fff;
        font-size: 23px;
      }

      .close {
        position: absolute;
        right: 0;
        top: 0;
      }
    }

    .images {
      position: relative;
      margin-top: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 30px;

      .hide-arrow {
        display: none;
      }

      @include screen('mobile') {
        margin-top: 50px;
        display: block;
      }

      .slider {
        width: 100%;
      }

      .slider > div > div {
        display: flex;
      }

      .image-container {
        width: 864px;
        height: 576px;
        display: flex !important;
        justify-content: center;
        align-items: center;

        @include screen('mobile') {
          max-height: 500px;
          width: 98vw;
        }

        &-inner {
          position: relative;

          @include screen('mobile') {
            margin: 0 5%;
          }
        }

        img {
          max-width: 864px;
          max-height: 576px;
          display: block;

          @include screen('mobile') {
            max-height: 500px;
            max-width: 100%;
          }
        }
      }

      svg {
        width: 48px;
        height: 48px;

        @include screen('mobile') {
          display: none;
        }
      }
    }
  }
}
