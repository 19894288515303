@import 'src/styles/theme.scss';
@import 'src/styles/mixins.scss';

.filter-section {
  position: relative;
  width: 100vw;
  height: auto;
  display: flex;
  justify-content: center;

  @include screen('small-desktop') {
    width: 1147px;
  }

  @include screen('tablet') {
    width: 770px;
  }

  @include screen('mobile') {
    width: 100%;
  }

  .content {
    width: 100%;
    max-width: 1475px;
    margin: 67px 20px 62px;
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 19px;
    border-bottom: solid 3px #03337d;

    &.no-search-results {
      margin: 67px 20px 26px;

      @include screen('tablet') {
        margin: 67px 20px 122px;
      }

      @include screen('mobile') {
        margin: 30px 20px 60px;
      }
    }

    @include screen('tablet') {
      margin: 67px 20px 122px;
    }

    @include screen('mobile') {
      flex-direction: column;
      align-items: center;
      flex-wrap: wrap;
      padding-bottom: 0;
      margin: 30px 20px 60px;
      border-bottom: none;
    }

    .title {
      margin-right: -8px;
      font-family: $bold-font;
      font-size: 50px;
      line-height: 54px;
      display: flex;
      align-items: center;

      @include screen('mobile') {
        width: 100%;
        margin-right: 0;
        margin-top: 0px;
        margin-bottom: 17px;
        align-items: normal;
        font-size: 28px;
        line-height: 106%;
        text-align: center;
        padding-bottom: 6px;
        border-bottom: solid 3px #03337d;
      }

      .stripes {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 4.5px;
        margin-bottom: 2px;

        @include screen('mobile') {
          row-gap: 3.5px;
          margin-top: 5px;
          margin-left: -11px;
        }

        div {
          width: 37px;
          height: 8px;
          background-color: $dark-blue;

          @include screen('mobile') {
            width: 27px;
            height: 6.3px;
          }
        }
      }

      span {
        margin-right: 9px;

        @include screen('mobile') {
          margin-right: 0px;
        }
      }
    }

    form {
      @include screen('tablet') {
        position: absolute;
        bottom: calc(-100% - 10px);
        right: 0;
      }

      @include screen('mobile') {
        width: 359px;
        right: initial;
        left: 50%;
        transform: translate(-50%, 0);
      }

      @include screen('ultra-small-mobile') {
        width: 302px;
      }
    }

    .subtitle {
      margin-top: 14px;
      margin-bottom: 21px;
      line-height: 21px;
      font-size: 18px;
      width: 376px;
      text-align: center;

      @include screen('small-mobile') {
        width: 314px;
      }
    }
  }
}
