@import 'src/styles/theme.scss';
@import 'src/styles/mixins.scss';

.stories-navigation-wrap {
  position: fixed;
  z-index: 10;
  bottom: -1px;
  margin-top: 40px;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #03337d;
  color: #dbf2ff;
  font-family: $medium-font;
  font-size: 16px;

  &.absolute {
    position: absolute;
    bottom: 367px;
    left: 0;

    @include screen('mobile') {
      bottom: 381px;
    }

    // @include screen('small-mobile') {
    //   bottom: 288px;
    // }
  }

  .next {
    a {
      i {
        margin-left: 5px;
        transform: rotateY(180deg);
      }
    }
  }

  .line {
    height: 24px;
    width: 1px;
    background-color: #dbf2ff7c;
    margin: 0 40px;
    @include screen('mobile') {
      margin: 0 48px;
    }
  }

  .previous {
    a {
      i {
        margin-right: 5px;
      }
    }
  }
}
