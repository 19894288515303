@import 'src/styles/theme.scss';
@import 'src/styles/mixins.scss';

.formSection {
  position: relative;
  height: 1360px;
  z-index: 2;
  background-color: rgba(13, 72, 150, 1);
  width: 100vw;
  display: flex;

  @include screen('mobile') {
    height: 1330px;
    align-items: center;
  }

  .background {
    width: 50%;
    height: 100%;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;

    @include screen('mobile') {
      display: none;
    }
  }

  .form {
    position: relative;
    z-index: 1;
    width: 50%;

    @include screen('medium-desktop') {
      width: 50%;
    }

    @include screen('mobile') {
      width: 100%;
      height: 100%;
      overflow: hidden;
    }

    iframe {
      display: block;
      height: 100%;
      width: 723px;
      border: none;
      margin: 0 12px 0 auto;

      @include screen('medium-desktop') {
        width: 100%;
      }

      @include screen('mobile') {
        margin: 0 auto;
      }
    }
  }
}
