@import 'src/styles/theme.scss';
@import 'src/styles/mixins.scss';

textarea:focus {
  outline: none;
}

p {
  margin: 0;
  padding: 0;
}

.textarea-wrap {
  position: relative;
  margin-top: 35px;
  width: 100%;
  height: 200px;
  padding: 5px 5px; // 12px 16px;
  border-radius: 5px;
  border: 1px solid #04476d;
  background-color: white;

  ::-webkit-scrollbar {
    width: 8px;
    background: transparent;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
    -webkit-box-shadow: none;
  }

  ::-webkit-scrollbar-thumb {
    background: $dark-blue;
    border-radius: 5px;
  }

  @include screen('mobile') {
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 5px 3px;
    height: 180px;
  }

  .textarea {
    border: none;
  }

  textarea {
    border: none;
    width: 100%;
    height: calc(200px - 12px);
    padding: 0 10px;
    font-size: 16px;
    font-family: $light-font;
    line-height: 1.5;
    color: $dark-blue;
    resize: none;

    @include screen('mobile') {
      height: calc(180px - 12px);
    }
  }

  .bottom-wrap {
    margin-top: -2px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .error {
      color: red;
      font-size: 14px;
    }

    .counter {
      color: $dark-blue;
      font-family: $regular-font;
      font-size: 12px;
    }
  }
}
