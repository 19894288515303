@import 'src/styles/theme.scss';
@import 'src/styles/mixins.scss';

h1 {
  margin: 0;
}

.story-bottom {
  height: 100px;
}

.page {
  display: flex;
  flex-direction: column;
  position: relative;
  font-family: $regular-font;
  color: $dark-blue;
  min-height: 100vh;
  background-image: url('src/assets/innerPage/story-background-gradient.jpg');
  background-repeat: no-repeat;
  background-size: cover;

  @include screen('mobile') {
    background: #f6fbff;
  }
}

.story-container {
  min-height: 100vh;
  padding-top: 71px;
  position: relative;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  .fallback {
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0;
  }

  @include screen('mobile') {
    padding-top: 40px;
  }

  .background {
    z-index: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    max-height: 780px;
    top: 0;
    left: 0;

    @include screen('mobile') {
      display: none;
    }

    img {
      height: 100%;
      width: 100%;
    }
  }

  .content {
    position: relative;
    z-index: 1;
    margin-top: 47px;
    width: 826px;
    padding: 60px 75px;
    border-radius: 10px;
    background-color: #f6fbff;

    @include screen('mobile') {
      width: 100%;
      margin-top: 38px;
      padding: 0px 15px;
      background-color: transparent;
    }

    .headline {
      position: relative;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      @include screen('mobile') {
        display: block;
      }

      .socials {
        padding-bottom: 13px;
        @include screen('mobile') {
          position: absolute;
          z-index: 2;
          left: 5px;
          top: 8px;
          padding-bottom: 0;
        }
      }

      .texts {
        padding-bottom: 12px;

        @include screen('mobile') {
          padding-bottom: 20px;
        }

        .title {
          padding-bottom: 11px;
          font-family: $bold-font;
          font-size: 50px;
          width: 450px;
          line-height: 45.25px;

          @include screen('mobile') {
            width: 100%;
            padding-bottom: 3px;
            padding-left: 60px;
            font-size: 39px;
            letter-spacing: 0.45px;
          }
        }

        .sender {
          width: 450px;
          font-size: 24px;
          line-height: 1;
          font-family: $medium-font;

          @include screen('mobile') {
            font-size: 20px;
            letter-spacing: 0.45px;
            width: 100%;
            padding-left: 40px;
          }
        }

        .subtitle {
          margin-top: 5px;
          font-family: $medium-font;
          font-size: 24px;
        }

        .tags {
          display: flex;
          margin-top: 5px;
          column-gap: 4px;

          @include screen('small-mobile') {
            margin-top: 16px;
          }

          .tag {
            padding: 5px 8px;
            font-size: 14px;
            background-color: #f4f4eb;
            color: #3e3e2b;
            border-radius: 30px;

            @include screen('small-mobile') {
              font-size: 12px;
              line-height: 11.5px;
              padding: 4px 6px;
            }
          }
        }
      }
    }

    .images-container {
      padding-top: 40px;
      padding-bottom: 15px;
      border-top: 1px solid #03337d;

      @include screen('small-mobile') {
        padding-top: 20px;
      }
    }

    .story {
      font-size: 20px;
      line-height: 30px;

      p {
        margin-top: 25px;

        @include screen('mobile') {
          margin-top: 5px;
        }
      }
    }

    .popup-btn-text {
      font-size: 14px;
      font-family: $medium-font;
      line-height: 150%;
      margin-top: 28px;
      text-decoration: underline;

      span {
        cursor: pointer;
      }
    }
  }
}
