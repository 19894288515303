@import 'src/styles/theme.scss';

/*
	Common media query mixin.

	Usage:
	.element {
		@include screen('tablet') {
			width: 90%;
		}
		@include screen('desktop') {
			width: 85%;
		}
		@include screen('(min-width: 999px)') {
			width: 1280px;
		}
	}
 */

@mixin screen($size) {
  $mediaQueries: (
    'ultra-small-mobile': $media-mobile-ultra,
    'small-mobile': $media-mobile-small,
    'mobile': $media-mobile-max,
    'tablet': $media-tablet,
    'small-desktop': $media-small-desktop,
    'medium-desktop': $media-medium-desktop,
  );

  /* Use predefined media query */
  @each $key, $value in $mediaQueries {
    @if $key == $size {
      @media only screen and #{$value} {
        @content;
      }
    }
  }

  /* Use custom media query */
  @if map-has-key($mediaQueries, $size) != true {
    @media only screen and #{$size} {
      @content;
    }
  }
}
