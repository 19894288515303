@import 'src/styles/theme.scss';
@import 'src/styles/mixins.scss';

.list-item {
  position: relative;
  height: 468px;
  width: 359px;
  padding: 17px 15px 15px 15px;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #03337d;

  @include screen('mobile') {
    height: fit-content;
  }

  @include screen('ultra-small-mobile') {
    width: 302px;
    padding: 15px 12px 10px 10px;
  }

  &.with-slider {
    .photo {
      margin-bottom: 11px;
    }
  }

  .title {
    width: 100%;
    color: $dark-blue;
    font-size: 31px;
    line-height: 32px;
    font-family: $bold-font;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    @include screen('ultra-small-mobile') {
      width: 262px;
      font-size: 26px;
      line-height: 23px;
    }

    span {
      cursor: pointer;
    }
  }

  .sender {
    font-family: $medium-font;
    font-size: 18px;
    line-height: 23.4px;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    padding: 2px 0 16px;
  }

  .photo {
    width: 329px;
    height: 207px;
    margin-bottom: 19px;
    position: relative;

    @include screen('ultra-small-mobile') {
      margin-top: 16px;
      width: 276px;
      height: 174px;
    }

    .image-credit {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;

      @include screen('ultra-small-mobile') {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }

    img {
      cursor: pointer;
      border-radius: 10px;
      object-fit: fill;
      width: 100%;
      height: 100%;

      @include screen('ultra-small-mobile') {
        border-radius: 8px;
      }
    }
  }

  .subtitle {
    overflow: hidden;
    display: -webkit-box;
    height: 49px;
    color: $dark-blue;
    font-size: 18px;
    line-height: 23.4px;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    white-space: pre-wrap;

    @include screen('ultra-small-mobile') {
      margin-top: 15px;
      height: 39px;
      font-size: 15px;
      line-height: 19.5px;
    }

    &.opened {
      height: 206.5px;
      -webkit-line-clamp: 9;
      padding-right: 1.5px;

      @include screen('mobile') {
        height: fit-content;
        max-height: 90px;
        -webkit-line-clamp: 4;
      }

      p {
        margin-top: 22px;

        @include screen('ultra-small-mobile') {
          margin-top: 10px;
        }

        &:first-child {
          margin-top: 0;
        }
      }
    }
  }

  .controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;

    .items-info {
      font-family: $medium-font;
      font-size: 18px;
      color: #03337d;
    }

    .nav {
      display: block;
      position: relative;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-color: #6fa7ff;
      transition: opacity 0.2s ease-in;

      &:hover {
        opacity: 0.9;

        @include screen('mobile') {
          opacity: 1;
        }
      }

      &:after,
      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 12px;
        height: 12px;
        border-bottom: 3px solid #fff;
        border-right: 3px solid #fff;
      }

      &.prev {
        &:after,
        &:before {
          transform: translate(-70%, -50%) rotate(-45deg);
        }
      }
      &.next {
        &:after,
        &:before {
          transform: translate(-30%, -50%) rotate(135deg);
        }
      }
    }
  }

  .button {
    position: absolute;
    width: 329px;
    bottom: 15px;
    transition: opacity 0.2s ease-in;

    &:hover {
      opacity: 0.9;

      @include screen('mobile') {
        opacity: 1;
      }
    }

    @include screen('mobile') {
      position: static;
      width: 100%;
      padding-bottom: 0;
      margin-top: 19px;
    }

    a {
      cursor: pointer;
      font-family: $bold-font;
      font-size: 16px;
      color: #ffffff;
      padding: 8px 0;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #6fa7ff;
      border-radius: 10px;
      column-gap: 7px;

      @include screen('ultra-small-mobile') {
        font-size: 13.5px;
      }
    }
  }
}

.fade {
  $transition-duration: 400ms;

  &-enter {
    .sender,
    .subtitle {
      opacity: 0;
    }
  }

  &-enter-active {
    .sender,
    .subtitle {
      opacity: 1;
      transition: opacity;
      transition-duration: #{$transition-duration};
    }
  }

  &-enter-done {
    .sender,
    .subtitle {
      opacity: 1;
    }
  }

  &-exit {
    .sender,
    .subtitle {
      opacity: 1;
    }
  }

  &-exit-active {
    .sender,
    .subtitle {
      opacity: 0;
      transition: opacity;
      transition-duration: #{$transition-duration};
    }
  }

  &-exit-done {
    .sender,
    .subtitle {
      opacity: 0;
    }
  }
}
