@import 'src/styles/theme.scss';
@import 'src/styles/mixins.scss';

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  height: 100vh;
  width: 100vw;
  background-color: #031824e4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: $dark-blue;
  font-family: $regular-font;

  @include screen('mobile') {
    justify-content: flex-end;
    height: 100%;
    background-color: transparent;
  }

  .mobile-underlay {
    display: none;
    position: fixed;
    width: 100%;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #031824e4;
    z-index: 1;

    @include screen('mobile') {
      display: block;
    }
  }

  .close {
    position: relative;
    z-index: 2;
    width: 755px;

    @include screen('mobile') {
      display: none;
    }
  }
}

.content {
  position: relative;
  z-index: 2;
  min-height: 343px;
  margin-top: 12px;
  width: 700px;
  background-color: #fafaf3;
  border-radius: 10px;

  @include screen('mobile') {
    width: 100%;
    min-height: 350px;
    margin-top: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .title {
    position: relative;
    display: flex;
    align-items: center;
    font-size: 26px;
    font-family: $bold-font;
    padding: 10px 20px 6px;
    width: 100%;
    border-bottom: 1px solid #04466d2c;

    @include screen('mobile') {
      justify-content: center;
      font-size: 24px;
      padding: 10px 10px 6px;
    }

    button {
      display: none;
      position: absolute;
      right: 10px;
      top: 12px;

      svg {
        width: 28px;
        height: 28px;
      }

      @include screen('mobile') {
        display: block;
      }
    }
  }

  .sent-content {
    width: 100%;
    height: calc(100% - 100px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .sent-title {
      margin-top: 5px;
      font-size: 30px;
      font-family: $bold-font;
    }

    .sent-subtitle {
      margin-top: -5px;
      font-size: 24px;
    }
  }

  .form {
    width: 100%;
    padding: 0 50px 35px 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 15px;

    @include screen('mobile') {
      padding: 0 20px 17px 20px;
      row-gap: 10px;
    }

    .button-wrap {
      margin-top: 5px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      @include screen('mobile') {
        margin-top: 10px;
      }

      .submit {
        width: 279px;
        height: 40px;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 5px;
        background-color: $dark-blue;
        color: #cde7f4;
        font-size: 16px;
        font-family: $bold-font;

        @include screen('mobile') {
          width: 100%;
        }

        &.disabled {
          opacity: 0.6;
          pointer-events: none;
          user-select: none;
        }
      }
    }
  }
}
