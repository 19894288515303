@import 'src/styles/theme.scss';
@import 'src/styles/mixins.scss';

.top-section {
  position: relative;
  height: 778px;
  width: 100%;
  background-size: cover;
  background-position: 50% 50%;

  @include screen('mobile') {
    height: 656px;
  }

  .info {
    position: absolute;
    top: 50%;
    right: 12.5%;
    transform: translate(0%, -48%);
    width: 510px;

    @include screen('mobile') {
      width: auto;
      top: auto;
      right: 24px;
      left: 24px;
      bottom: 77px;
      transform: translate(0%, 0%);
    }

    .logo {
      display: block;
      width: 450px;
      margin-bottom: 29px;

      @include screen('mobile') {
        width: 100%;
        margin-bottom: 19px;
      }
    }

    .text {
      font-family: $regular-font;
      font-size: 22px;
      line-height: 26px;
      color: #fff;

      b {
        font-family: $bold-font;
        font-weight: normal;
      }

      @include screen('mobile') {
        font-size: 18px;
        line-height: 22px;
        text-align: center;
      }
    }
  }

  .arrow {
    bottom: 24px;
    left: 50%;
    transform: translate(-50%, 0);
    border: solid transparent;
    border-color: rgba(136, 183, 213, 0);
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-top-color: #fbce77;
    border-width: 12px;
  }
}
