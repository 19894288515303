@import 'src/styles/theme.scss';
@import 'src/styles/mixins.scss';

.image-credit {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 30%;
  width: 100%;
  display: flex;
  align-items: flex-end;
  color: #fff;
  font-family: $regular-font;
  font-size: 10px;
  line-height: 1;
  padding: 0 7px 5px 5px;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 65.47%,
    rgba(0, 0, 0, 0.189452) 83.07%,
    rgba(0, 0, 0, 0.359959) 98.08%,
    #000000 105.33%
  );

  &.right {
    justify-content: flex-start;
  }

  &.left {
    justify-content: flex-end;
  }
}
