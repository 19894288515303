@import 'src/styles/theme.scss';
@import 'src/styles/mixins.scss';

.list-wrapper {
  min-height: 50px;
  position: relative;
  z-index: 2;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 90px;

  @include screen('mobile') {
    margin-bottom: 90px;
    margin-top: 30px;
  }

  .scroll-point {
    position: absolute;
    z-index: -1;
    bottom: 200px;

    @include screen('small-mobile') {
      bottom: 100px;
    }
  }

  .results-amount-wrapper {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 5px;
    width: 1472px;
    padding-bottom: 16px;

    @include screen('small-desktop') {
      width: 1101px;
    }

    @include screen('tablet') {
      width: 730px;
    }

    @include screen('mobile') {
      width: calc(100% - 17px * 2);
      margin: 0 auto;
    }

    .results-amount {
      width: fit-content;
      height: 35px;
      padding: 0 8px;
      background-color: #fff;
      border-radius: 6px;
      font-size: 21px;
      line-height: 35px;
      color: $dark-blue;
      font-family: $bold-font;

      &.no-results {
        height: 45px;
        font-size: 27px;
        line-height: 45px;
        padding: 0 12px;
        border-radius: 12px;

        @include screen('mobile') {
          height: 36px;
          font-size: 18px;
          line-height: 36px;
          padding: 0 10px;
          border-radius: 10px;
        }
      }

      @include screen('mobile') {
        background-color: #fff;
        height: 30px;
        padding: 0 8px;
        font-size: 16px;
        line-height: 30px;
        border-radius: 6px;
      }
    }

    .search-params {
      display: flex;
      flex-wrap: wrap;
      gap: 6px;

      div {
        height: 35px;
        font-family: $medium-font;
        font-size: 21px;
        line-height: 35px;
        color: $dark-blue;
        border-radius: 6.45px;
        padding: 0 8px;
        background-color: #fff;

        @include screen('mobile') {
          height: 30px;
          font-size: 15px;
          line-height: 30px;
          font-weight: 500;
        }
      }
    }
  }

  .list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 15px;
    grid-row-gap: 15px;

    @include screen('small-desktop') {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @include screen('tablet') {
      grid-template-columns: 1fr 1fr;
    }

    @include screen('mobile') {
      grid-template-columns: 1fr;
    }
  }

  .page-switcher {
    margin-top: 20px;

    @include screen('mobile') {
      margin-top: 54px;
    }

    ul {
      direction: ltr;

      li {
        &:first-child,
        &:last-child {
          transform: rotateY(180deg);
        }

        button {
          font-family: $medium-font;
          color: #04476d;
          font-size: 21px;

          @include screen('mobile') {
            padding: 0;
          }
        }

        svg {
          fill: #04476d;
          height: 52px;
          width: 52px;
          @include screen('mobile') {
            height: 52px;
            width: 52px;
          }
        }
      }
    }
  }
}
