@import 'src/styles/theme';
@import 'src/styles/mixins.scss';

.header {
  position: fixed;
  z-index: 100;
  width: 100vw;
  height: 71px;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;

  @include screen('mobile') {
    height: 44px;
  }

  &::before {
    display: block;
    content: '';
    position: absolute;
    z-index: 0;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: #6fa7ff;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s, visibility 0.3s;
    @include screen('mobile') {
      background-color: #296cb3;
    }
  }

  .n12-logo-wrap {
    position: relative;
    z-index: 1;
    height: 26px;
    margin: 23px 30px 0 0;

    @include screen('mobile') {
      height: 18px;
      margin: 12px 12px 0 0;
    }

    &.hide {
      @include screen('small-mobile') {
        display: none;
      }
    }

    img {
      height: 100%;
    }
  }

  .inner-logo {
    cursor: pointer;
    height: 42px;
    margin: 0 13px 0 0;

    @include screen('mobile') {
      height: 20px;
      width: 225px;
      margin: 0;
    }

    @include screen('small-mobile') {
      height: 16px;
      width: 193px;
      margin-right: -27px;
      margin-top: 0;
    }
  }

  button {
    position: relative;
    z-index: 1;
    padding: 0 13px 0 12px;
    padding-top: 2px;
    cursor: pointer;
    height: 38px;
    background-color: rgba(255, 255, 255, 0.8);
    border: 2px solid #03337d;
    color: #03337d;
    font-family: $medium-font;
    border-radius: 5px;
    margin: 16px 0 0 35px;
    font-size: 16px;
    transition: opacity 0.2s ease-in;

    &:hover {
      opacity: 0.9;

      @include screen('mobile') {
        opacity: 1;
      }
    }

    @include screen('mobile') {
      height: 29px;
      font-size: 14px;
      padding: 1.5px 10px 1px;
      margin: 8px 0 0 15px;
      background-color: rgba(255, 255, 255, 0.8);
    }

    &.hide-text {
      span {
        @include screen('mobile') {
          visibility: hidden;
        }
      }
    }

    i {
      width: 20px;
      margin-right: 10px;
      margin-bottom: 1px;

      @include screen('mobile') {
        display: none;
      }
    }
  }

  &.background {
    &::before {
      opacity: 1;
      visibility: visible;
    }
  }

  .back {
    margin-left: 50px;
    position: relative;
    z-index: 1;
    font-size: 16px;
    font-family: $medium-font;
    display: flex;
    align-items: center;
    column-gap: 4px;
    cursor: pointer;
    color: #fff;

    @include screen('small-mobile') {
      margin-left: 12px;
      font-size: 14px;
    }
  }

  a {
    position: relative;
    z-index: 1;
    font-size: 16px;
    font-family: $medium-font;
    display: flex;
    align-items: center;
    column-gap: 4px;

    @include screen('small-mobile') {
      margin-left: 12px;
      font-size: 14px;
    }
  }

  .logo-wrapper {
    margin-left: 0;
    opacity: 0;
    display: flex;
    margin: 0 0 10px 0;
    align-items: center;
    visibility: hidden;
    transition: all 0.3s ease;

    @include screen('small-mobile') {
      margin: 0;
    }

    &.show {
      opacity: 1;
      visibility: visible;
    }
  }
}
