@import 'src/styles/theme.scss';
@import 'src/styles/mixins.scss';

.footer {
  position: relative;
  z-index: 2;
  width: 100vw;
  padding: 53px 0 54px;
  display: flex;
  justify-content: center;
  align-items: center;

  background: radial-gradient(53.92% 53.92% at 50.03% 46.08%, #f6f6f6 0%, #e7e7e7 100%);

  @include screen('mobile') {
    padding: 44px 0 28px;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .logos {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 32px;

      img {
        height: 26px;
      }
    }

    .text {
      font-size: 14px;
      line-height: 17px;
      font-family: $light-font;
      color: rgba(3, 51, 125, 1);
      width: 491px;

      @include screen('mobile') {
        width: 323px;
        font-size: 16px;
        line-height: 19px;
      }

      @include screen('small-mobile') {
        width: 323px;
      }

      div {
        width: 100%;
        text-align: center;
      }

      .title {
        padding-bottom: 20px;
        font-family: $regular-font;
      }

      .top {
        @include screen('small-mobile') {
          // align-self: center;
        }
      }

      .bottom {
      }
    }

    .socials {
      padding-top: 32px;
      width: 100%;
      display: flex;
      justify-content: center;
      column-gap: 21px;

      @include screen('mobile') {
        padding-top: 28px;
      }

      div {
        & i {
          cursor: pointer;
          width: 28px;
          opacity: 0.8;
          transition: all 0.3s ease;

          @include screen('mobile') {
            width: vw(28);
          }

          &:hover {
            opacity: 1;
          }
        }
      }
    }

    .rabbi-credit {
      padding-top: 20px;
      text-align: center;
      font-size: 12px;
      line-height: 14px;
      color: #000;
      a {
        color: #000;
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}
