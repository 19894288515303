@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;700&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

@font-face {
  font-family: 'Yonit-Light';
  src: url('./fonts/Yonit-Light.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Yonit-Regular';
  src: url('./fonts/Yonit-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Yonit-Medium';
  src: url('./fonts/Yonit-Medium.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Yonit-Bold';
  src: url('./fonts/Yonit-Bold.ttf');
  font-weight: normal;
  font-style: normal;
}
