@import 'src/styles/theme.scss';
@import 'src/styles/mixins.scss';

.socials {
  display: flex;
  align-items: center;
  column-gap: 13px;
  min-width: 100px;

  @include screen('mobile') {
    flex-direction: column-reverse;
  }

  .share-icon {
    cursor: pointer;
    width: 28px;
    height: 28px;

    @include screen('mobile') {
      width: 35px;
      height: auto;
      margin-top: 5px;
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }
}

.minified {
  position: relative;
  transition: all 0.25s ease-in-out;
  visibility: hidden;
  z-index: 2;
  opacity: 0;

  @include screen('mobile') {
    position: absolute;
    z-index: 2;
    top: -118px;
    left: -33px;
    height: 233px;
    transition: all 0.3s ease;
    transform: scaleY(0);
  }

  &.open {
    visibility: visible;
    opacity: 1;
    transform: scaleY(1);

    @include screen('mobile') {
      position: absolute;
      z-index: 3;
      top: -5px;
      left: -33px;
    }
  }

  .icon {
    overflow: visible;
    width: 34px;

    // &.facebook {
    //   margin-top: 46px;
    //   margin-right: -140.6px;
    // }

    // &.twitter {
    //   margin-top: 96px;
    //   margin-right: -93.2px;
    // }

    // &.email {
    //   margin-top: 144px;
    //   margin-right: -46px;
    // }

    // &.whats-app {
    //   margin-top: 194px;
    //   margin-right: 0.6px;
    // }
  }
}

.toggleBtn {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: visible;

  &::before {
    position: absolute;
    z-index: 1;
    content: '';
    display: block;
    top: -3px;
    right: -2.7px;
    height: 241px;
    width: 40px;
    background-color: $dark_blue;
    border-radius: 10px;
    transition: all 0.3s ease;
    opacity: 0;
    transform: scaleY(0);
  }

  &.open {
    margin: 0;

    &::before {
      opacity: 0.12;
      transform: scaleY(1);
    }

    svg {
      opacity: 0.5;
      width: 35px;
      height: 35px;
      transition: all 0.3s ease;
    }
  }

  i {
    position: relative;
    z-index: 3;

    svg {
      width: 35px !important;
      height: 35px !important;
    }
  }
}
